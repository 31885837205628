<script setup>
import {Link} from '@inertiajs/vue3';
import Button from '@/Components/Button.vue';
import CartItem from '@/Components/CartItem.vue';
import {useI18n} from "vue-i18n";
import {computed} from "vue";

const locale = computed(() => useI18n().locale);
defineProps({
    close: Function,
    cartItem: Object,
})
</script>

<template>
    <div
        class="
            fixed top-[90px] left-auto right-0 z-40 px-6 py-5 overflow-y-auto
            duration-500 transition-transform translate-x-full
            bg-white dark:bg-gray-800 w-full sm:w-[30rem]
        "
        tabindex="-1"
    >
        <div class="flex justify-between">
            <h5 class="text-xl text-black-dark font-normal">{{ $t('addedToYourCard') }}</h5>

            <Button
                class="text-black hover:text-green"
                @click.stop="close()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                >
                    <path
                        d="M17.8945 4.10547L4.10595 17.8941"
                        stroke="currentColor"
                        class="hover:stroke-green"
                        stroke-width="2.5"
                        stroke-linecap="square"
                    />
                    <path
                        d="M4.10547 4.10547L17.8941 17.8941"
                        stroke="currentColor"
                        class="hover:stroke-green"
                        stroke-width="2.5"
                        stroke-linecap="square"
                    />
                </svg>
                <span class="sr-only">Close modal</span>
            </Button>
        </div>

        <hr class="mt-3 border-gray-darkest">

        <div class="py-4 overflow-y-auto">
            <CartItem
                :item="cartItem"
                :on-cart-preview="true"
            />

            <div class="w-full text-black space-y-3 text-desc-13">
                <div>
                    <Link
                        :href="locale.value === 'uk' ? '/cart' : `/${locale.value}/cart`"
                        @click.stop="close()"
                    >
                        <Button
                            kind="sharp-small"
                            secondary
                            class="flex justify-between uppercase w-full border-black"
                        >
                            {{ $t('seeCard') }} <span>{{ $page.props.cart_count }}</span>
                        </Button>
                    </Link>
                </div>
                <div>
                    <Link
                        :href="locale.value === 'uk' ? '/checkout' : `/${locale.value}/checkout`"
                        @click.stop="close()"
                    >
                        <Button
                            kind="sharp-small"
                            primary
                            class="uppercase w-full"
                        >
                            {{ $t('goToCheckout') }}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</template>
