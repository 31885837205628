<script setup>
import emitter from '@/plugins/eventBus.js'
import {onBeforeUnmount, onMounted} from "vue";
import {scrollToContainer} from "@/plugins/helpers.js";

const {componentId, content, settings} = defineProps({
    componentId: String,
    content: Object,
    settings: Object,
});

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)))
onBeforeUnmount(() => emitter.$off(componentId));
</script>

<template>
    <component
        :is="settings.tag || 'p'"
        :id="componentId"
        class="md:w-4/5 lg:max-w-[960px] text-2xl md:text-3xl font-normal text-center mx-auto font-philosopher"
        :style="{ color: settings.text_color}"
        v-html="content.text"
    ></component>
</template>
