<script setup>
import emitter from '@/plugins/eventBus.js'
import {onBeforeUnmount, onMounted} from "vue";
import {scrollToContainer} from "@/plugins/helpers.js";

const {componentId} = defineProps({
    componentId: String,
    content: Object,
    settings: Object,
});

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)))
onBeforeUnmount(() => emitter.$off(componentId));
</script>

<template>
    <div class="container pb-10 sm:pb-16 lg:pb-24">
        <component
            :is="settings.tag"
            :id="componentId"
            class="w-4/5 sm:w-3/5 text-2xl md:text-4xl font-normal text-center mx-auto font-philosopher"
            :style="{ color: settings.text_color}"
            v-html="content.text"
        />
    </div>
    <div class="w-full lg:w-4/5 xl:w-3/4 grid grid-cols-4 mx-auto overflow-hidden">
        <div class="flex flex-col items-center p-1 sm:px-3 md:px-6 md:py-2 mx-auto">
            <div
                class="
                    flex items-center justify-center bg-black rounded-full
                    h-16 w-16 sm:h-20 sm:w-20 md:h-28 md:w-28 lg:h-32 lg:w-32 xl:w-36 xl:h-36 p-5 md:p-7
                    transition-transform duration-500 ease-in-out
                    hover:rotate-[360deg] hover:scale-105 hover:delay-0 delay-200
                "
            >
                <svg
                    width="70"
                    height="76"
                    viewBox="0 0 70 76"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <ellipse
                        cx="45.0382"
                        cy="24.8116"
                        rx="21.8116"
                        ry="21.8116"
                        stroke="white"
                        stroke-width="5"
                    />
                    <path
                        d="M28.3594 41.6123L2.99706 66.9746"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M5.98438 65.8986L13.0858 73"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M10.0391 60.826L17.1405 67.9275"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                    />
                </svg>
            </div>

            <div
                class="text-black text-xs sm:text-base md:text-lg lg:text-xl text-center
                     font-normal pt-4 md:pt-6 xl:pt-8"
            >
                {{$t('advantagesBlockKey11')}}<br>{{$t('advantagesBlockKey12')}}
            </div>
        </div>

        <div class="flex flex-col items-center p-1 sm:px-3 md:px-6 md:py-2 mx-auto">
            <div
                class="
                    flex items-center justify-center bg-black rounded-full
                    h-16 w-16 sm:h-20 sm:w-20 md:h-28 md:w-28 lg:h-32 lg:w-32 xl:w-36 xl:h-36 p-5 md:p-7
                    transition-transform duration-500 ease-in-out
                    hover:rotate-[360deg] hover:scale-105 hover:delay-0 delay-200
                "
            >
                <svg
                    width="80"
                    height="76"
                    viewBox="0 0 80 76"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M39.8948 51.6959C53.3419 51.6959 64.2427 40.7948 64.2427 27.3479C64.2427 13.9009 53.3419 3 39.8948 3C26.4479 3 15.5469 13.9009 15.5469 27.3479C15.5469 40.7948 26.4479 51.6959 39.8948 51.6959Z"
                        stroke="white"
                        stroke-width="5"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M3 73C6.73944 66.5217 12.1184 61.1422 18.5962 57.4017C25.0738 53.6613 32.4222 51.6921 39.9024 51.6921C47.3823 51.6921 54.7307 53.6613 61.2086 57.4017C67.6861 61.1422 73.0653 66.5217 76.8048 73"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>

            <div
                class="text-black text-xs sm:text-base md:text-lg lg:text-xl text-center
                     font-normal pt-4 md:pt-6 xl:pt-8"
            >
                {{$t('advantagesBlockKey21')}}<br>{{$t('advantagesBlockKey22')}}
            </div>
        </div>

        <div class="flex flex-col items-center p-1 sm:px-3 md:px-6 md:py-2 mx-auto">
            <div
                class="
                    flex items-center justify-center bg-black rounded-full
                    h-16 w-16 sm:h-20 sm:w-20 md:h-28 md:w-28 lg:h-32 lg:w-32 xl:w-36 xl:h-36 p-5 md:p-7
                    transition-transform duration-500 ease-in-out
                    hover:rotate-[360deg] hover:scale-105 hover:delay-0 delay-200
                "
            >
                <svg
                    width="74"
                    height="71"
                    viewBox="0 0 74 71"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M55 8V66H67V8H55ZM53 3C51.3431 3 50 4.34314 50 6V68C50 69.6569 51.3431 71 53 71H69C70.6569 71 72 69.6569 72 68V6C72 4.34315 70.6569 3 69 3H53Z"
                        fill="white"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M56.5357 6.3175L38.317 61.3818L49.7096 65.1512L67.9283 10.0869L56.5357 6.3175ZM56.2075 0.942349C54.6345 0.421904 52.9375 1.27516 52.417 2.84815L32.9418 61.71C32.4214 63.283 33.2746 64.98 34.8476 65.5005L50.0378 70.5264C51.6108 71.0468 53.3078 70.1935 53.8283 68.6206L73.3035 9.75872C73.8239 8.18572 72.9707 6.48866 71.3977 5.96821L56.2075 0.942349Z"
                        fill="white"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M60.6416 6.45368L6.45032 27.125L10.7272 38.337L64.9184 17.6657L60.6416 6.45368ZM64.6005 2.803C64.01 1.25494 62.2763 0.4787 60.7283 1.06921L2.79964 23.1662C1.25159 23.7567 0.475345 25.4903 1.06585 27.0384L6.7683 41.9877C7.3588 43.5358 9.09245 44.312 10.6405 43.7215L68.5691 21.6245C70.1172 21.034 70.8934 19.3004 70.3029 17.7523L64.6005 2.803Z"
                        fill="white"
                    />
                </svg>
            </div>

            <div
                class="text-black text-xs sm:text-base md:text-lg lg:text-xl text-center
                     font-normal pt-4 md:pt-6 xl:pt-8"
            >
                {{$t('advantagesBlockKey31')}}<br>{{$t('advantagesBlockKey32')}}
            </div>
        </div>

        <div class="flex flex-col items-center p-1 sm:px-3 md:px-6 md:py-2 mx-auto">
            <div
                class="
                    flex items-center justify-center bg-black rounded-full
                    h-16 w-16 sm:h-20 sm:w-20 md:h-28 md:w-28 lg:h-32 lg:w-32 xl:w-36 xl:h-36 p-5 md:p-7
                    transition-transform duration-500 ease-in-out
                    hover:rotate-[360deg] hover:scale-105 hover:delay-0 delay-200
                "
            >
                <svg
                    width="80"
                    height="76"
                    viewBox="0 0 80 76"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M39.7656 23.1207V72.8047"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M57.3491 18.7352C52.9241 23.1213 39.7656 23.1213 39.7656 23.1213C39.7656 23.1213 39.7656 9.96291 44.1518 5.53793C45.9019 3.78787 48.2756 2.80469 50.7504 2.80469C53.2253 2.80469 55.599 3.78787 57.3491 5.53793C59.0992 7.28801 60.0823 9.6616 60.0823 12.1366C60.0823 14.6116 59.0992 16.9852 57.3491 18.7352Z"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M22.1785 18.7352C26.6034 23.1213 39.7619 23.1213 39.7619 23.1213C39.7619 23.1213 39.7619 9.96291 35.3758 5.53793C33.6256 3.78787 31.2523 2.80469 28.7771 2.80469C26.3022 2.80469 23.9286 3.78787 22.1785 5.53793C20.4285 7.28801 19.4453 9.6616 19.4453 12.1366C19.4453 14.6116 20.4285 16.9852 22.1785 18.7352Z"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M73.9207 23.1207H5.60525C3.89028 23.1207 2.5 24.5109 2.5 26.226V38.647C2.5 40.362 3.89028 41.7522 5.60525 41.7522H73.9207C75.6357 41.7522 77.026 40.362 77.026 38.647V26.226C77.026 24.5109 75.6357 23.1207 73.9207 23.1207Z"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M70.8159 41.7522V69.6994C70.8159 70.5229 70.4886 71.3129 69.9064 71.8952C69.3241 72.4774 68.5342 72.8047 67.7107 72.8047H11.8162C10.9926 72.8047 10.2028 72.4774 9.62043 71.8952C9.03811 71.3129 8.71094 70.5229 8.71094 69.6994V41.7522"
                        stroke="white"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>

            <div
                class="text-black text-xs sm:text-base md:text-lg lg:text-xl text-center
                     font-normal pt-4 md:pt-6 xl:pt-8"
            >
                {{$t('advantagesBlockKey41')}}<br>{{$t('advantagesBlockKey42')}}
            </div>
        </div>
    </div>
</template>
