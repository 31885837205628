<script setup>
import ResponsivePicture from "@/Components/ResponsivePicture.vue";
import {encode} from 'js-base64';
import GridBlock from "@/Components/Grid/GridBlock.vue";
import ColBlock from "@/Components/Grid/ColBlock.vue";
import emitter from '@/plugins/eventBus.js'
import {onBeforeUnmount, onMounted} from "vue";
import {scrollToContainer} from "@/plugins/helpers.js";

const {componentId, content, settings} = defineProps({
    componentId: String,
    content: Object,
    settings: Object,
});

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)))
onBeforeUnmount(() => emitter.$off(componentId));
</script>

<template>
    <GridBlock
        :id="componentId"
        :cols-proportions="settings.col_proportion"
    >
        <template
            v-for="(image, index) in content.images"
            :key="encode(image.url)"
        >
            <ColBlock
                :index="index"
                :cols-proportions="settings.col_proportion"
            >
                <ResponsivePicture
                    :data="image"
                    img-classes="object-cover w-full h-full"
                />
            </ColBlock>
        </template>
    </GridBlock>
</template>
