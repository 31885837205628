import './bootstrap.js'
import {createApp, h} from 'vue'
import {initFlowbite} from 'flowbite'
import {createInertiaApp, router} from '@inertiajs/vue3'
import InertiaTitle from 'inertia-title/vue3'
import store from "./store.js";
import i18n from "@/i18n.js";
import VueSplide from '@splidejs/vue-splide';
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import * as Sentry from '@sentry/vue';

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        return pages[`./Pages/${name}.vue`]
    },
    setup({el, App, props, plugin}) {
        store.commit('setSettings', props.initialPage.props.global)
       const app = createApp({render: () => h(App, props)})
        .use(plugin)
        .use(i18n)
        .use(InertiaTitle)
        .use(store)
        .use(VueSplide)
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [Sentry.replayIntegration()],
            environment: import.meta.env.APP_ENV,
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/fdoor\.ua/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        app.mount(el)
    },
}).then(() => {
    document.getElementById('app').removeAttribute('data-page');

    router.on('finish', () => initFlowbite())
})