<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import Breadcrumb from "@/Components/Breadcrumb.vue";
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {usePage} from "@inertiajs/vue3";
import {useStore} from "vuex";

const {t} = useI18n();
const {state} = useStore();
const locale = computed(() => useI18n().locale);
const breadcrumbs = computed(() => [
    {
        title: t('titleHome'),
        disabled: false,
        href: state.links?.home?.[locale.value.value],
    },
    {
        title: t('titleUserAgreement'),
        disabled: true,
        href: '/',
    },
]);

</script>

<template>
    <AppLayout>
        <section class="bg-neutral-100 py-10">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <div class="container py-8 lg:py-12 prose">
            <h1 v-html="usePage().props.h1"></h1>
            <div v-html="usePage().props.intro"></div>
        </div>
        
    </AppLayout>
</template>
