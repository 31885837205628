<script setup>
import {useForm, usePage} from '@inertiajs/vue3'
import {useI18n} from 'vue-i18n';
import TextInput from '@/Components/TextInput.vue';
import Select from '@/Components/Select.vue';
import {ref} from "vue";

const props = defineProps({
    data: Object,
    totalPrice: Number,
    endpoint: String,
    orderDetails: Object,
    hasSelect: Boolean,
    requestType: [String, null]
})

const {locale} = useI18n();

const formData = ref({
    [usePage().props.honeypot.nameFieldName]: '',
    [usePage().props.honeypot.validFromFieldName]: usePage().props.honeypot.encryptedValidFrom,
    contact_name: null,
    contact_phone: null,
    contact_email: null,
    message: null,
    product: props?.data,
    _locale: locale.value,
});

if (props.hasSelect) {
    formData.value.request_type = '';
}

if (props.requestType) {
    formData.value.request_type = props.requestType;
    formData.value.post_id = usePage().props.id;
}

const form = useForm(formData.value);

const selectOptions = ['doors-product', 'doors-custom-product', 'accessories'];

const transformOrderDetails = data => {
    const result = [];

    for (const key in data) {
        const item = data[key];
        if (typeof item.value !== 'undefined') {
            result.push({[item.title]: item.value});
        }
    }

    return result;
}

const emit = defineEmits(['submitted'])

const submit = () => {
    form.product = { ...props.data, price: props.totalPrice }; // manual data update

    const onSuccess = () => {
        form.reset('contact_email', 'contact_phone', 'contact_name', 'message');
        emit('submitted');
    }

    setTimeout(() => {

        if (props.orderDetails) {
            form
            .transform(data => ({
                ...data,
                orderDetails: transformOrderDetails(props.orderDetails)
            }))
            .post(props.endpoint, {preserveScroll: true, preserveState: false, onSuccess})
        } else {
            form.post(props.endpoint, {preserveScroll: true, onSuccess})
        }
    }, 500)
}
</script>

<template>
    <form
        class=""
        :class="{'custom-autofill-bg': props.hasSelect}"
        novalidate
        @submit.prevent="submit"
    >
        <div class="flex justify-between items-end font-medium mb-12 2xl:mb-16 text-xl">
            <h2 class="text-black leading-none">{{ $t('contact') }}</h2>
            <p class="text-green"><span class="align-top">*</span>{{ $t('necessarily') }}</p>
        </div>
        <div class="space-y-12 2xl:space-y-16">
            <div
                v-if="$page.props.honeypot.enabled"
                :name="`${$page.props.honeypot.nameFieldName}_wrap`"
                style="display:none;"
            >
                <input
                    :id="$page.props.honeypot.nameFieldName"
                    v-model="form[$page.props.honeypot.nameFieldName]"
                    type="text"
                    :name="$page.props.honeypot.nameFieldName"
                />
                <input
                    v-model="form[$page.props.honeypot.validFromFieldName]"
                    type="text"
                    :name="$page.props.honeypot.validFromFieldName"
                />
            </div>
            <TextInput
                v-model="form.contact_name"
                required
                name="name"
                :error="form.errors.contact_name"
                :label="$t('name')"
            />
            <TextInput
                v-model="form.contact_phone"
                required
                name="phone"
                :error="form.errors.contact_phone"
                :label="$t('phone')"
            />
            <TextInput
                v-model="form.contact_email"
                required
                name="email"
                :error="form.errors.contact_email"
                :label="$t('email')"
            />
            <Select
                v-if="props.hasSelect"
                v-model="form.request_type"
                :options="selectOptions"
                :has-borders="false"
                :error="form.errors.request_type"
                name="topic"
                required
                :placeholder="$t('topic')"
            />
        </div>
        <div class="mt-10 2xl:mt-12 space-y-10 2xl:space-y-12">
            <div>
                <textarea
                    v-model="form.message"
                    class="
                        py-5 px-6 w-full text-green border-green text-xl bg-transparent
                        focus:border-green focus:ring-0 focus:ring-transparent
                        placeholder:text-green placeholder:font-light
                    "
                    :placeholder="$t('yourMessages')"
                ></textarea>
                <div
                    v-if="form.errors.message"
                    class="text-sm text-red"
                >{{ form.errors.message }}
                </div>
            </div>

            <button
                type="submit"
                kind="big"
                class="
                    block w-full font-medium text-white text-xl uppercase bg-green leading-none 
                    hover:bg-black mx-auto py-6 px-10 rounded-44 transition-colors duration-300
                "
                @click.prevent="submit"
            >{{ $t('sendRequest') }}
            </button>
        </div>
    </form>
</template>
