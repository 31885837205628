<script setup>
import {ref} from 'vue';
import {useElementSize, useToggle} from '@vueuse/core';
import Button from '@/Components/Button.vue';

const props = defineProps({
    items: Array,
    hasTitle: {
        type: Boolean,
        default: true,
    },
    gapX: {
        type: String,
        default: '2px',
    },
    loop: Boolean,
    gapY: Boolean,
    showAll: Boolean,
    startIndex: {
        type: Number,
        default: 0,
    }
})

const splideRef = ref(null);
const splideTitleRef = ref(null);
const {height} = useElementSize(splideTitleRef);
const visibleElHeight = ref(height.value);

const [showSlider, toggleSlider] = useToggle(true);
const options = ref({
    drag: 'free',
    perMove: 1,
    arrows: false,
    pagination: false,
    autoWidth: true,
    gap: props.gapX,
    start: props.startIndex,
})

if (props.loop) {
    options.value.type = 'fade';
    options.value.rewind = true;
}

const goToPrevSlide = () => splideRef.value.go('<');
const goToNextSlide = () => splideRef.value.go('>');

const showAllEls = () => {
    visibleElHeight.value = height.value;

    toggleSlider();
}
</script>

<template>
    <div
        class="splide-container"
        :class="{ 'relative': !props.hasTitle }"
    >
        <div
            v-show="showSlider"
            ref="splideTitleRef"
            class="flex flex-row justify-between"
            :class="{ 'relative': props.hasTitle }"
        >
            <h3
                v-if="props.hasTitle"
                class="mb-16 text-center sm:text-left text-black text-xl w-full py-3"
                :class="{'sm:mb-5': gapY, 'sm:mb-4': !gapY}"
            >
                <slot name="title">
                    <Button
                        class="text-green hover:text-black transition-colors duration-300 underline"
                        @click="showAllEls(false)"
                    >
                        {{ $t('seeAllDoorHandlesCollection') }}
                    </Button>
                </slot>
            </h3>

            <div
                class="splide-arrows flex justify-center"
                :class="{'position-on-side': !hasTitle}"
            >
                <Button
                    class="flex text-black justify-center splide__arrow custom-splide__arrow--prev splide__arrow--prev rounded-full"
                    stroke="currentColor"
                    @click="goToPrevSlide"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        scale="1"
                        class="!fill-[none] !scale-100"
                    >
                        <path
                            d="M6.5 10.9141C5.92857 10.3807 2.92857 7.13628 1.5 5.58073L6.5 0.914062"
                            stroke-width="2"
                            stroke="currentColor"
                            class="hover:stroke-green"
                        />
                        <line
                            y1="-1"
                            x2="9"
                            y2="-1"
                            transform="matrix(1 0 0 -1 2 4.82812)"
                            stroke-width="2"
                            stroke="currentColor"
                            class="hover:stroke-green"
                        />
                    </svg>
                </Button>
                <Button
                    class="splide__arrow text-black custom-splide__arrow--next splide__arrow--next rounded-full"
                    @click="goToNextSlide"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        class="!fill-[none] !scale-[-1]"
                    >
                        <path
                            d="M6.5 10.9141C5.92857 10.3807 2.92857 7.13628 1.5 5.58073L6.5 0.914062"
                            stroke="currentColor"
                            class="hover:stroke-green"
                            stroke-width="2"
                        />
                        <line
                            y1="-1"
                            x2="9"
                            y2="-1"
                            transform="matrix(1 0 0 -1 2 4.82812)"
                            stroke-width="2"
                            stroke="currentColor"
                            class="hover:stroke-green"
                        />
                    </svg>
                </Button>
            </div>
        </div>
        <template v-if="props.showAll">
            <div
                v-show="!showSlider"
                class="flex justify-end mb-2 mx-1 sm:mx-0"
                :style="{ height: visibleElHeight + 'px' }"
            >
                <Button
                    class="text-black hover:text-green"
                    @click="toggleSlider(true)"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                    >
                        <path
                            d="M17.8945 4.10547L4.10595 17.8941"
                            stroke="currentColor"
                            class="hover:stroke-green"
                            stroke-width="2.5"
                            stroke-linecap="square"
                        />
                        <path
                            d="M4.10547 4.10547L17.8941 17.8941"
                            stroke="currentColor"
                            class="hover:stroke-green"
                            stroke-width="2.5"
                            stroke-linecap="square"
                        />
                    </svg>
                </Button>
            </div>
        </template>

        <Splide
            v-if="showSlider"
            ref="splideRef"
            :options="options"
            aria-label="Наявні моделі"
        >
            <template
                v-for="(item, index) in props.items"
                :key="index"
            >
                <SplideSlide class="w-fit">
                    <slot
                        name="slide"
                        :item="item"
                    />
                </SplideSlide>
            </template>
        </Splide>

        <template v-if="props.showAll">
            <div
                v-show="!showSlider"
                class="my-div grid gap-[1px] sm:gap-1 xl:gap-5 justify-between grid-cols-auto-fit-minmax-184px mx-1 sm:mx-0"
            >
                <template v-for="item in props.items">
                    <slot
                        name="slide"
                        :item="item"
                    />
                </template>
            </div>
        </template>
    </div>
</template>

<style>
.splide__arrow.custom-splide__arrow--prev {
    @apply right-[77px] left-auto text-black-dark;
}

.splide__arrow.custom-splide__arrow--next {
    @apply right-0;
}

.splide-arrows .splide__arrow:hover:not(:disabled) {
    @apply text-green opacity-100 bg-white;
}

.splide__arrow.custom-splide__arrow--prev svg,
.splide__arrow.custom-splide__arrow--next svg {
    @apply w-auto h-auto fill-[none];
}

.splide__arrow.custom-splide__arrow--prev,
.splide__arrow.custom-splide__arrow--next {
    @apply bg-white w-[53px] h-[53px] top-14 sm:top-0 opacity-100 translate-y-0;
}

.position-on-side .splide__arrow.custom-splide__arrow--prev,
.position-on-side .splide__arrow.custom-splide__arrow--next {
    @apply bottom-0 top-auto lg:top-[40%] w-[100px] h-[100px] shadow-menu;
}

.position-on-side .splide__arrow.custom-splide__arrow--prev svg,
.position-on-side .splide__arrow.custom-splide__arrow--next svg {
    @apply w-5 h-5;
}

.position-on-side .splide__arrow.custom-splide__arrow--prev {
    @apply left-0 -bottom-28 lg:-left-40;
}

.position-on-side .splide__arrow.custom-splide__arrow--next {
    @apply -bottom-28 lg:-right-40;
}

@media (max-width: 469px) {
    .splide__arrow.custom-splide__arrow--prev,
    .splide__arrow.custom-splide__arrow--next {
        @apply top-20;
    }
}

@media screen and (min-width: 550px) and (max-width: 640px) {
    .splide__list {
        transform: translateX(6vw);
    }
}

</style>
