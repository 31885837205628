<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import NovaComponentsLoader from "@/Components/NovaComponentsLoader.vue";
import Breadcrumb from '@/Components/Breadcrumb.vue';
import {useI18n} from 'vue-i18n';
import {computed} from "vue";
import {useStore} from "vuex";

const {t} = useI18n();
const locale = computed(() => useI18n().locale);
const {state} = useStore();
const breadcrumbs = computed(() => [
    {
        title: t('titleHome'),
        disabled: false,
        href: state.links?.home?.[locale.value.value],
    },
    {
        title: t('partitionGlassInteriorTitle'),
        disabled: true,
        href: '/',
    },
]);

</script>

<template>
    <AppLayout>
        <section class="bg-neutral-100 py-10 ">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <NovaComponentsLoader avoid-padding-top/>
    </AppLayout>
</template>
