<script setup>
import {ref} from 'vue';
import {Link} from '@inertiajs/vue3';
import Button from '@/Components/Button.vue';
import ResponsivePicture from "@/Components/ResponsivePicture.vue";

const props = defineProps({
    href: String,
    src: String,
    coverImage: Object,
    title: String,
});
const isHovered = ref(false);
</script>
<template>
    <article
        class="bg-gray p-6 w-auto hover:font-bold cursor-pointer"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
    >
        <Link :href="props.href">
            <figure>
                <div class="flex justify-center w-full">
                    <slot name="image">
                        <ResponsivePicture
                            :data="coverImage"
                        />
                    </slot>
                </div>
                <figcaption class="flex justify-around items-center mt-6">
                    <h3 class="text-black text-sm md:text-xl uppercase ">{{ props.title }}</h3>
                    <div>
                        <Button
                            kind="round-extra-small"
                            :class="{ '!bg-black': isHovered }"
                        >
                            <svg
                                class="w-4 h-4 md:w-6 md:h-6 text-white-light"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m9 5 7 7-7 7"
                                />
                            </svg>
                            <span class="sr-only">Cкляні перегородки</span>
                        </Button>
                    </div>
                </figcaption>
            </figure>
        </Link>
    </article>
</template>