<script setup>
import MegaMenuItem from '@/Components/MegaMenuItem.vue';
import {encode} from "js-base64";

const props = defineProps({
    menuItems: Array
})
</script>

<template>
    <div class="bg-neutral-100 absolute top-full left-0 right-0 shadow-menu">
        <div class="grid max-w-[1920px] py-11 mx-auto text-gray-900 grid-cols-2 px-5 lg:px-4 xl:px-[3.65%] gap-10">
            <template
                v-for="(menuItem, index) in props.menuItems"
                :key="encode(menuItem.src + index)"
            >
                <MegaMenuItem
                    :link-src="menuItem.linkSrc"
                    :items="menuItem.items"
                />
            </template>
        </div>
    </div>
</template>