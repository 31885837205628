<script>
import { usePage } from '@inertiajs/vue3';

import Faq from '@/Components/NovaComponents/Faq.vue'
import ImageWithContentBlock from '@/Components/NovaComponents/ImageWithContentBlock.vue'
import HeadingBlock from '@/Components/NovaComponents/HeadingBlock.vue';
import HeadingWithImageBlock from '@/Components/NovaComponents/HeadingWithImageBlock.vue';
import ImageGrid from '@/Components/NovaComponents/ImageGrid.vue';
import ContactsBlock from '@/Components/NovaComponents/ContactBlock.vue';
import AdvantagesBlock from '@/Components/NovaComponents/AdvantagesBlock.vue';
import PartitionsListBlock from '@/Components/NovaComponents/PartitionsListBlock.vue';
import SimpleContentBlock from '@/Components/NovaComponents/SimpleContentBlock.vue';
import ImageGridWithTextBlock from '@/Components/NovaComponents/ImageGridWithTextBlock.vue';

export default {
    components: {
        Faq,
        ImageWithContentBlock,
        HeadingBlock,
        HeadingWithImageBlock,
        ImageGrid,
        ContactsBlock,
        AdvantagesBlock,
        SimpleContentBlock,
        ImageGridWithTextBlock,
        PartitionsListBlock,
    },
    props: {
        avoidPaddingTop: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        applyPaddings(curBg, index) {
            const classes = [];
            const nextBg = usePage().props.content[index + 1]?.settings?.bg?.toLowerCase();
            const differentBg = nextBg !== curBg?.toLowerCase();

            if (!(this.avoidPaddingTop && index === 0)) { // avoid pt for the first section and add to others
                classes.push('pt-10 sm:pt-16 lg:pt-24');
            }

            if (differentBg) {
                classes.push('pb-10 sm:pb-16 lg:pb-24');
            }

            return classes;
        }
    }
}
</script>

<template>
    <template v-for="(component, index) in $page.props.content">
        <section :style="{'background': component?.settings?.bg}">
            <div
                class="container"
                :class="applyPaddings(component?.settings?.bg, index)"
            >
                <component
                    :componentId="component.id"
                    :is="component.name"
                    :content="component.data"
                    :settings="component.settings"
                />
            </div>
        </section>
    </template>
</template>

<style scoped>

</style>
