<script setup>
const value = defineModel({type: String});
const props = defineProps({
    name: String,
    required: Boolean,
    placeholder: String,
    hasBorders: {
        type: Boolean,
        default: true,
    },
    options: [Array, Object],
    error: String,
})
</script>

<template>
    <div>
        <select
            v-model="value"
            :name="props.name"
            class="
                bg-transparent border-green text-green leading-snug block w-full
                appearance-none focus:ring-0 focus:ring-transparent peer focus:border-green
            "
            :class="{
                'border py-5 px-3 text-desc-13': props.hasBorders,
                'border-0 border-b py-3 px-0 text-xl': !props.hasBorders,
                'font-normal': value,
                'font-light': !value,
            }"
            :required="props.required"
        >
            <option value="">{{ props.placeholder }}{{ props.required ? '*' : '' }}</option>
            <template v-for="option in options">
                <option :value="option">{{ $t(option) }}</option>
            </template>
        </select>
        <div
            v-if="props.error"
            class="absolute text-sm text-red"
        >{{ props.error }}</div>
    </div>
</template>
