<script setup>
import {ref} from "vue";

defineProps({data: Object, imgClasses: [String, Object, null]})

const mediaList = ref({
    lg: '(min-width:1280px)',
    md: '(min-width:992px)',
    sm: '(min-width:576px)',
    xs: null,
})

const extensions = ref(['webp', 'png', 'jpg'])
</script>

<template>
    <picture v-if="data != null">
        <template v-for="extension in extensions">
            <template
                v-for="(media, dimension) in mediaList"
                v-if="data[extension] != null"
            >
                <source
                    v-if="data[extension][dimension]"
                    :media="media"
                    :type="`image/${extension}`"
                    :srcset="data[extension][dimension]"
                />
            </template>
        </template>
        <img
            :src="data.url"
            :alt="data?.alt"
            :class="imgClasses"
        >
    </picture>
</template>

<style scoped>

</style>