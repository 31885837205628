<script setup>
const props = defineProps({
    colsProportions: String
})

const getCols = () => {
    switch (props.colsProportions) {
        case 'cols-1/2':
            return 2;
        case 'cols-2/3-1/3':
        case 'cols-1/3-2/3':
        case 'cols-1/3':
            return 3;
        case'cols-2/4-1/4-1/4':
        case'cols-1/4':
        case 'cols-1/4-2/4-1/4':
        case 'cols-1/4-1/4-2/4':
            return 4;

        case 'cols-3/6-2/6':
        case 'cols-4/6-2/6':
            return 6;
        default:
            return 1;
    }
};

</script>

<template>
    <div
        class="grid grid-cols-1 gap-5 lg:gap-12"
        :class='{
            "lg:grid-cols-2": getCols() === 2,
            "lg:grid-cols-3": getCols() === 3,
            "lg:grid-cols-4": getCols() === 4,
            "lg:grid-cols-6": getCols() === 6,
        }'
    >
        <slot/>
    </div>
</template>

<style scoped>

</style>