<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import Breadcrumb from "@/Components/Breadcrumb.vue";
import NovaComponentsLoader from "@/Components/NovaComponentsLoader.vue";
import {useI18n} from "vue-i18n";
import ResponsivePicture from "@/Components/ResponsivePicture.vue";
import {computed} from "vue";
import {useStore} from "vuex";

const {t} = useI18n();
const locale = computed(() => useI18n().locale);
const {state} = useStore();
const breadcrumbs = computed(() => [
    {
        title: t('titleHome'),
        disabled: false,
        href: state.links?.home?.[locale.value.value],
    },
    {
        title: t('titlePartnership'),
        disabled: true,
        href: '/',
    },
]);
</script>

<template>
    <AppLayout>
        <section class="bg-neutral-100 py-10 ">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <section class="bg-neutral-100">
            <div class="container relative">
                <h1
                    class="
                        absolute right-2 sm:right-4 pt-4 md:pt-12 pl-8 pr-4 sm:pr-7 md:pr-10 lg:pr-20 font-normal
                        font-philosopher text-black text-3xl md:text-4xl lg:text-6xl xl:text-7xl text-right
                    "
                    v-text="$page.props.h1"
                >
                </h1>

                <ResponsivePicture
                    class="mx-auto"
                    :data="$page.props.coverImage"
                    img-classes="object-cover w-full"
                />
            </div>
        </section>

        <NovaComponentsLoader/>
    </AppLayout>
</template>
