<script setup>
import {Link} from "@inertiajs/vue3";
import Button from '@/Components/Button.vue';
import ResponsivePicture from "@/Components/ResponsivePicture.vue";

const props = defineProps({
    href: String,
    imgSrc: [String, Object],
    caption: String,
});
</script>

<template>
    <div class="news-block w-full overflow-hidden">
        <Link
            :href="props.href"
            class="relative hover:font-bold"
        >
            <div class="relative w-full">
                <div class="relative h-0 pt-[56%] overflow-hidden">
                    <div class="absolute lef-0 top-0 w-full h-full">
                        <div class="transform transition duration-300 hover:scale-110">
                            <slot name="image">
                                <ResponsivePicture
                                    v-if="imgSrc instanceof Object"
                                    :data="imgSrc"
                                    img-classes="w-full"
                                />
                                <picture
                                    v-else
                                    class="w-full"
                                >
                                    <source
                                        :src="imgSrc"
                                        type="image/png"
                                    />
                                    <img
                                        :src="imgSrc"
                                        :alt="props.caption"
                                    />
                                </picture>
                            </slot>
                        </div>

                    </div>
                </div>
                <div
                    class="
                        absolute bottom-0 flex justify-between items-center
                        px-6 pb-6 sm:px-10 sm:pb-7 md:px-8 xl:px-12 md:pb-8 w-full gap-5 2k:gap-10
                    "
                >
                    <h3 class="text-white text-2xl sm:text-3xl md:text-2xl lg:text-[32px]">{{ props.caption }}</h3>
                    <div>
                        <Button kind="round-small">
                            <svg
                                class="w-5 h-5 md:w-6 md:h-6 text-white-light"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m9 5 7 7-7 7"
                                />
                            </svg>
                        </Button>
                    </div>
                </div>
            </div>
        </Link>
    </div>
</template>