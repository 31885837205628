<script setup>
import emitter from '@/plugins/eventBus.js'
import {onBeforeUnmount, onMounted} from "vue";
import {scrollToContainer} from "@/plugins/helpers.js";
import PartitionModels from "@/Components/PartitionModels.vue";

const {componentId} = defineProps({
    componentId: String,
    content: Array
})

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)))
onBeforeUnmount(() => emitter.$off(componentId));
</script>

<template>
    <PartitionModels
        :id="componentId"
        :partition-models="content"
    />
</template>

<style scoped>

</style>