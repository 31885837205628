<script setup>
import {Link, router} from '@inertiajs/vue3';
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";
import emitter from "@/plugins/eventBus.js";

const locale = computed(() => useI18n().locale);
const {state} = useStore();
const coordinatesUrl = ref(
    state?.settings?.coordinates ?
        `https://www.google.com/maps/search/?api=1&query=${state?.settings?.coordinates?.longitude},${state?.settings?.coordinates?.latitude}` :
        '#'
);
const aboutLink = ref(state?.links?.['about-us']?.[locale.value.value]);
const cleanAddress = computed(() => {
    const address = `${locale.value.value}` === 'ru' ?
        state?.settings?.office_address_ru : state?.settings?.office_address;
    return address.replace(/<br\s*\/?>/g, '');
});

const onNavigateTo = (link, componentId) => {
    if (window.location.href.includes(link)) {
        emitter.$emit(componentId);
        return;
    }

    router.visit(link, {
        onFinish: () => emitter.$emit(componentId)
    })
}
</script>

<template>
    <footer class="bg-black px-5 ">
        <div class="container">
            <div class="grid grid-cols-1 gap-2 sm:gap-5 md:gap-8 pt-5 sm:pt-16 lg:pt-24 sm:grid-cols-3">
                <div class="mt-6 md:mt-0">
                    <h4 class="mb-4 md:mb-6 text-sm font-bold text-white uppercase">{{ $t('catalog') }}</h4>
                    <ul class="text-sm lg:text-base text-white font-medium">
                        <li class="mb-3 md:mb-4">
                            <Link
                                :href="state?.links?.['doors-product']?.[locale.value]"
                                class="hover:text-green"
                            >
                                {{ state?.links?.['doors-product']?.title?.[locale.value] }}
                            </Link>
                        </li>
                        <li class="mb-3 md:mb-4">
                            <Link
                                :href="state?.links?.['doors-custom-product']?.[locale.value]"
                                class="hover:text-green"
                            >
                                {{ state?.links?.['doors-custom-product']?.title?.[locale.value] }}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div class="mt-6 md:mt-0">
                    <h4 class="mb-4 md:mb-6 text-sm font-bold text-white uppercase">{{ $t('titleAbout') }}</h4>
                    <ul class="text-sm lg:text-base text-white font-medium">
                        <template v-for="item in state?.links?.['about-us']?.blocks?.[locale.value]">
                            <li class="mb-3 md:mb-4">
                                <a
                                    :href="aboutLink + `/#${item?.id}`"
                                    class="hover:text-green"
                                    @click.prevent="onNavigateTo(aboutLink, item?.id)"
                                >
                                    {{ item.title }}
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
                <!-- <div>
                    <h2 class="mb-6 text-sm font-bold text-white uppercase">{{ $t('information') }}</h2>
                    <ul class="text-sm lg:text-base text-white font-medium">
                        <li class="mb-3 md:mb-4">
                            <Link
                                href="#"
                                class="text-base"
                            >Оплата</Link>
                        </li>
                        <li class="mb-3 md:mb-4">
                            <Link href="#">Доставка</Link>
                        </li>
                        <li class="mb-3 md:mb-4">
                            <Link href="#">Гарантійне обслуговування</Link>
                        </li>
                        <li class="mb-3 md:mb-4">
                            <Link href="#">Виклик майстра</Link>
                        </li>
                    </ul>
                </div> -->
                <div class="mt-6 md:mt-0">
                    <h4 class="mb-4 md:mb-6 text-sm font-bold text-white uppercase">{{ $t('titleContacts') }}</h4>
                    <ul class="text-sm lg:text-base text-white font-medium">
                        <li class="mb-3 md:mb-4">
                            <a
                                :href="coordinatesUrl"
                                :target="coordinatesUrl === '#' ? '_self' : '_blank'"
                                class="hover:text-green"
                            >
                                {{ cleanAddress }}
                            </a>
                        </li>
                        <li class="mb-3 md:mb-4">
                            <a
                                :href="`tel:${state?.settings?.main_phone_number}`"
                                class="hover:text-green"
                            >
                                {{ state?.settings?.main_phone_number }}
                            </a><br>
                            <a
                                :href="`mailto:${state?.settings?.main_email}`"
                                class="hover:text-green"
                            >
                                {{ state?.settings?.main_email }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="my-6 border-white sm:mx-auto lg:my-8"/>
            <div class="grid grid-cols-2 py-4 pb-12 bg-black justify-items-start items-baseline gap-2 md:gap-8">
                <span class="text-xxs text-white sm:text-center">© {{ (new Date()).getFullYear() }} <Link href="/">F’DOOR</Link></span>
                <div class="flex flex-col sm:flex-row sm:mt-4 sm:justify-center md:mt-0 sm:space-x-5 rtl:space-x-reverse">
                    <Link
                        :href="state?.links?.['user-agreement']?.[locale.value]"
                        class="text-white text-xxs uppercase"
                    >
                        {{ $t('userAgreement') }}
                    </Link>
                    <Link
                        :href="state?.links?.['privacy-policy']?.[locale.value]"
                        class="text-white text-xxs uppercase"
                    >
                        {{ $t('privacyPolicy') }}
                    </Link>
                </div>
            </div>
        </div>
    </footer>
</template>
