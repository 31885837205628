<script setup>
import ButtonsGroup from '@/Components/ButtonsGroup.vue';
import emitter from '@/plugins/eventBus.js'
import {onBeforeUnmount, onMounted} from "vue";
import {scrollToContainer} from "@/plugins/helpers.js";

const {componentId, content, settings} = defineProps({
    componentId: String,
    content: Object,
    settings: Object,
});

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)))
onBeforeUnmount(() => emitter.$off(componentId));
</script>

<template>
    <component
        :is="settings.tag"
        :id="componentId"
        class="w-4/5 sm:w-3/5 text-2xl md:text-4xl font-normal text-center mx-auto font-philosopher"
        :style="{ color: settings.text_color}"
        v-html="content.text"
    >
    </component>

    <ButtonsGroup class="mt-8 sm:mt-12 lg:mt-20" />
</template>
