<script setup>
import PartitionModel from '@/Components/PartitionModel.vue';

const props = defineProps({
    partitionModels: Array
})
</script>

<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 xl:gap-16">
        <template
            v-for="partitionModel in props.partitionModels"
            :key="partitionModel.id"
        >
            <PartitionModel
                :cover-image="partitionModel.coverImage"
                :href="partitionModel.href"
                :title="partitionModel.title"
            />
        </template>
    </div>
</template>
