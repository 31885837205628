<script setup>
import ResponsivePicture from "@/Components/ResponsivePicture.vue";
import emitter from '@/plugins/eventBus.js'
import {onBeforeUnmount, onMounted} from "vue";
import {scrollToContainer} from "@/plugins/helpers.js";

const {componentId, content} = defineProps({
    componentId: String,
    content: Object,
});

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)))
onBeforeUnmount(() => emitter.$off(componentId));
</script>

<template>
    <div
        :id="componentId"
        class="grid grid-cols-2 md:grid-cols-4 gap-6"
    >
        <template
            v-for="image in content.images"
            :key="image.id"
        >
            <div
                class="relative col-span-1"
            >
                <ResponsivePicture
                    :data="image.image"
                    img-classes="object-cover w-full h-full"
                />
                <div class="absolute bottom-2 md:bottom-4 right-3 md:right-6 text-[#38383B] text-xs">{{ image.title }}</div>
            </div>
        </template>
    </div>
</template>
