<script setup>
const model = defineModel({type: [Number, Object]});
const props = defineProps({
    radios: Array,
    name: String,
    kind: String,
    position: {
        type: String,
        default: 'between'
    },
    direction: {
        type: String,
        default: 'row'
    },
});

const selectClasses = 'text-black text-desc-13 text-center font-medium bg-transparent py-2 px-3 border border-green rounded-3xl min-w-20 cursor-pointer whitespace-nowrap hover:bg-gray';

const radioBtnClasses = {
    small: `min-w-[77px]`,
    medium: `min-w-[82px]`,
    big: `min-w-[161px]`,
    column: 'grid sm:grid-cols-[161px_161px] gap-3 lg:gap-y-3 lg:gap-x-5 w-full sm:w-auto',
    row: 'flex space-x-3',
    gridRow: `grid auto-fit-minmax-82px gap-3 w-full justify-start ${
        props.position === 'start' ? 'lg:justify-start' : 'lg:justify-between'}`
}
</script>

<template>
    <div :class="radioBtnClasses[props.direction]">
        <div
            v-for="(radio, index) in radios"
            class="flex items-center cursor-pointer"
        >
            <input
                :id="`radio-${name}-${index}`"
                v-model="model"
                type="radio"
                :value="radio"
                name="inline-radio-group"
                class="hidden"
            >
            <label
                :for="`radio-${name}-${index}`"
                :class="[selectClasses, radioBtnClasses[kind], { '!bg-gray': model === radio || model.value === radio.value }]"
            >
                {{ radio?.value || radio }} {{ typeof radio.value === 'number' || typeof radio === 'number' ? 'мм' : '' }}
            </label>
        </div>
    </div>
</template>
