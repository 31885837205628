<script setup>
import {useI18n} from "vue-i18n";
import {usePage} from "@inertiajs/vue3";
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import {initCollapses, initDropdowns} from 'flowbite';
import NewModal from "@/Components/NewModal.vue";
import Header from '@/Layouts/Header.vue';
import Footer from '@/Layouts/Footer.vue';

const {locale} = useI18n({useScope: 'global'});
const isOpenModal = ref(false)

locale.value = usePage().props?.locale ?? import.meta.env.VITE_APP_LOCALE

watch(() => usePage().props.requestId,
  (newRequestId) => {
    if (newRequestId != null) {
      isOpenModal.value = true;
    }
  })

const requestId = ref(usePage().props.requestId)

onMounted(() => {
  initCollapses();
  initDropdowns();
  if (requestId.value != null) {
    isOpenModal.value = true;
  }
  document.querySelector('html').setAttribute('lang', usePage().props?.locale)
})

onBeforeUnmount(() => {
  isOpenModal.value = false;
})
</script>

<template>
    <Header/>
    <main class="mt-[80px] lg:mt-[90px]">
        <slot></slot>
    </main>
    <Teleport to="body">
        <NewModal
            :is-open="isOpenModal"
            :has-cross="true"
            classes="p-5 sm:p-10 2xl:p-12 w-[90vw] sm:w-[540px] lg:w-[720px]"
            @close="isOpenModal = false"
        >
            <div class="text-3xl text-green text-center font-philosopher">
                <p>
                    {{ $t('yourOrder') + ' ' + $page.props.requestId + ' ' + $t('yourOrderSendToManagerText') }}
                </p>
                <p class="mt-5">{{ $t('thank') }}</p>
            </div>
        </NewModal>
    </Teleport>
    <Footer/>
</template>

<style scoped>

</style>