<script setup>
import {computed, ref} from "vue";
import {Link, useForm, usePage} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import Toggle from '@/Components/Toggle.vue';
import TextInput from '@/Components/TextInput.vue';
import AppLayout from "@/Layouts/AppLayout.vue";
import Button from "@/Components/Button.vue";
import Breadcrumb from '@/Components/Breadcrumb.vue';
import CartItem from "@/Components/CartItem.vue";
import {useStore} from "vuex";

const {t} = useI18n();
const {state} = useStore();
const locale = computed(() => useI18n().locale);

const breadcrumbs = computed(() => [
    {
        title: t('titleHome'),
        disabled: false,
        href: state.links?.home?.[locale.value.value],
    },
    {
        title: t('titleCheckout'),
        disabled: true,
        href: '/',
    },
]);

const formattedItemsPrice = computed(() => new Intl.NumberFormat('uk-UA', {
    currencyDisplay: "narrowSymbol", style: 'currency', currency: 'UAH'
}).format(usePage().props.totalPrice));

const deliveryCost = ref(0);

const formattedTotalPrice = computed(() => new Intl.NumberFormat('uk-UA', {
    currencyDisplay: "narrowSymbol", style: 'currency', currency: 'UAH'
}).format(usePage().props.totalPrice + deliveryCost.value))

const form = useForm({
    request_type: 'order',
    contact_name: null,
    contact_phone: null,
    contact_email: null,
    delivery: t('addressDelivery'),
    payment: t('bankAccount'),
    _locale: locale.value
})

const submit = () => {
    form.post('/checkout')
}
</script>

<template>
    <AppLayout>
        <section class="py-10 ">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <section class="">
            <div class="container md:flex mb-16">
                <div class="w-full md:w-1/2">
                    <div class="space-y-12">
                        <div class="space-y-5">
                            <div class="flex justify-between items-end font-medium">
                                <h2 class="text-black text-xl leading-none">{{ t('receiver') }}</h2>
                                <p class="text-green text-desc-13"><span class="align-top">*</span>{{ t('necessarily') }}</p>
                            </div>
                            <div>
                                <div class="space-y-5">
                                    <TextInput
                                        v-model="form.contact_name"
                                        required
                                        name="name"
                                        :error="form.errors.contact_name"
                                        :placeholder="t('fullName')"
                                        has-borders
                                    />
                                    <TextInput
                                        v-model="form.contact_phone"
                                        required
                                        name="phone"
                                        :error="form.errors.contact_phone"
                                        :placeholder="t('phone')"
                                        has-borders
                                    />
                                    <TextInput
                                        v-model="form.contact_email"
                                        required
                                        name="email"
                                        :error="form.errors.contact_email"
                                        :placeholder="t('email')"
                                        has-borders
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="space-y-5">
                            <h3 class="text-black text-xl leading-none">{{ t('deliveryType') }}</h3>
                            <div class="flex justify-between items-center text-black">
                                <Toggle
                                    v-model="form.delivery"
                                    type="radio"
                                    name="delivery"
                                    :label="t('addressDelivery')"
                                />
                                <p class="text-xxs font-normal">{{ t('carrierTariffs') }}</p>
                            </div>
                            <div class="flex justify-between items-center text-black">
                                <Toggle
                                    v-model="form.delivery"
                                    type="radio"
                                    name="delivery"
                                    :label="$t('pickup')"
                                />
                                <p class="text-xxs font-normal">{{ t('carrierTariffs') }}</p>
                            </div>
                            <div class="flex justify-between items-center text-black">
                                <Toggle
                                    v-model="form.delivery"
                                    type="radio"
                                    name="delivery"
                                    :label="$t('courierDelivery')"
                                />
                                <p class="text-xxs font-normal">{{ t('negotiable')}}</p>
                            </div>
                            <div class="flex justify-between items-center text-black">
                                <Toggle
                                    v-model="form.delivery"
                                    type="radio"
                                    name="delivery"
                                    :label="t('warehousePickup')"
                                />
                                <p class="text-xxs text-red font-normal">{{ t('free') }}</p>
                            </div>
                        </div>

                        <div class="space-y-5 flex flex-col">
                            <h3 class="text-black text-xl leading-none">{{ t('paymentMethod') }}</h3>
                            <Toggle
                                v-model="form.payment"
                                type="radio"
                                name="payment"
                                :label="t('bankAccount')"
                            />
                            <Toggle
                                v-model="form.payment"
                                type="radio"
                                name="payment"
                                :label="t('card')"
                            />
                            <Toggle
                                v-model="form.payment"
                                type="radio"
                                name="payment"
                                :label="t('receiptWithPrepayment')"
                            />
                        </div>
                    </div>
                </div>
                <div class="w-full md:w-1/2 pb-12 md:pb-0 md:pl-8 lg:pl-24 xl:pl-52 text-black text-desc-13">
                    <div class="text-right">
                        <Link
                            :href="locale.value === 'uk' ? '/cart' : `/${locale.value}/cart`"
                            class="text-green hover:text-black underline font-medium"
                        >{{ t('edit') }}
                        </Link>
                    </div>
                    <template
                        v-for="item in $page.props.cartItems"
                        :key="item.id"
                    >
                        <CartItem
                            :item="item"
                            :on-checkout="true"
                            class="border-b border-black last:border-none pt-8 first:pt-0"
                        />
                    </template>
                    <div>
                        <p class="flex justify-between font-semibold mt-12">{{ t('earlyTotal') }}
                            <span>{{ formattedItemsPrice }}</span></p>
                        <p class="flex justify-between font-medium my-4">
                            {{ t('delivery') }}
                            <span class="lowercase">{{ form.delivery }}</span>
                        </p>
                        <p class="flex text-xl justify-between font-semibold mb-4">{{ t('earlyTotal') }}
                            <span>{{ formattedTotalPrice }}</span></p>
                        <div>
                            <Button
                                kind="sharp-small"
                                type="submit"
                                primary
                                class="uppercase w-full mb-6"
                                @click="submit"
                            >
                                {{ t('confirmOrder') }}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </AppLayout>
</template>
