<script setup>
import {computed, reactive} from "vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import NovaComponentsLoader from "@/Components/NovaComponentsLoader.vue";
import Breadcrumb from '@/Components/Breadcrumb.vue';
import ProductCharacteristic from '@/Components/ProductCharacteristic.vue';

import PageTypes from "@/Enums/pageTypes.js";
import {usePage} from "@inertiajs/vue3";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const props = usePage().props
const {state} = useStore();
const {t} = useI18n();
const locale = computed(() => useI18n().locale);

const breadcrumbs = computed(() => [
    {
        title: t('titleHome'),
        disabled: false,
        href: state.links?.home?.[locale.value.value],
    },
    {
        title: props.h1,
        disabled: true,
        href: '/',
    },
]);

const price = () => state.settings[PageTypes.DOORS_CUSTOM_PRODUCT + '_price'] || 0
const oldPrice = () => state.settings[PageTypes.DOORS_CUSTOM_PRODUCT + '_price_old'] || 0

const productCharacteristic = reactive({
    title: props.h1,
    pageType: PageTypes.DOORS_CUSTOM_PRODUCT,
    price: price(),
    priceBeforeDiscount: oldPrice(),
    doorHeight: props.doorHeight,
    doorWidth: props.doorWidth,
    doorOpenOptions: props.doorOpenOptions,
    doorTrimFabric: props.doorTrimFabric,
    doorFrame: props?.doorFrame,
    doorEdge: props?.doorEdge,
    doorCurtains: props?.doorCurtains,
    doorMagneticStopper: props?.doorMagneticStopper,
    doorMagneticMechanism: props?.doorMagneticMechanism,
});
</script>

<template>
    <AppLayout>
        <section class="bg-neutral-100 py-10 ">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <section class="bg-neutral-100 ">
            <div class="container pb-10 sm:pb-16 lg:pb-24">
                <ProductCharacteristic v-bind="productCharacteristic"/>
            </div>
        </section>

        <NovaComponentsLoader avoid-padding-top />
    </AppLayout>
</template>
