<script setup>
import { nextTick, ref } from "vue";
import L from "leaflet";
import "leaflet.markercluster";
import { GestureHandling } from "leaflet-gesture-handling";

const { property } = defineProps({
    property: {
        type: Object,
        default: () => ({
            latitude: 50.4719472,
            longitude: 30.4090806,
        })
    }
})
const mapContainer = ref(null);
const map = ref(null);

const loadMap = () => {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    const latLng = L.latLng(property.latitude, property.longitude);

    map.value = L.map(mapContainer.value, {
        gestureHandling: true,
        ...{zoomControl: true, zoom: 1, zoomAnimation: false, fadeAnimation: false, markerZoomAnimation: true}
    }).setView(latLng).setZoom(12);
    // TODO: change provider to stadiamaps-alidade-smooth
    L.tileLayer('https://tiles.tdev.agency/?s={s}&z={z}&x={x}&y={y}&provider=stadiamaps-alidade-smooth&language=en-US', {
        maxZoom: 20
    }).addTo(map.value)
    const icon = L.divIcon({
        html: `
            <svg xmlns="http://www.w3.org/2000/svg" width="92" height="115" viewBox="0 0 40 51" fill="none">
                <defs>
                    <mask id="hole">
                    <rect width="40" height="51" fill="white"/>
                    <path d="M34.0216 19.0073C34.0939 26.7386 27.8078 33.0969 20.0765 33.1692C12.2731 33.2414 5.98694 27.0276 5.91469 19.2241C5.84243 11.4929 12.0563 5.13451 19.8598 5.06226C27.591 5.06226 33.9494 11.2761 34.0216 19.0073Z" fill="black"/>
                    </mask>
                </defs>
                <path d="M21.1588 0.293457C20.7976 0.293457 20.364 0.293457 20.0028 0.293457C19.6415 0.293457 19.208 0.293457 18.8467 0.293457C8.44206 0.365711 0.132812 8.89172 0.132812 19.2963C0.132812 33.8195 20.0028 50.2935 20.0028 50.2935C20.0028 50.2935 39.8727 33.8195 39.8727 19.2963C39.8004 8.89172 31.5635 0.365711 21.1588 0.293457Z" fill="#5A7B7E" mask="url(#hole)"/>
            </svg>`,
        className: "",
        iconSize: [92, 115],
        iconAnchor: [46, 115],
    });

    L.marker(latLng, { icon }).addTo(map.value);
}

nextTick(loadMap);
</script>
<template>
    <div class="block w-full bg-white">
        <div class="bg-none text-gray-900">
            <div class="relative z-10">
                <div class="w-full h-[400px] xl:h-[600px] 2xl:h-[800px]">
                    <div    
                        ref="mapContainer"
                        class="h-full"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>
<style src="leaflet/dist/leaflet.css"/>
<style src="leaflet-gesture-handling/dist/leaflet-gesture-handling.css"/>
