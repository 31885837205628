<script setup>
import {computed} from "vue";

const value = defineModel({type: Number});
const {product_specification} = defineProps({
    id: Number,
    src: Object,
    title: String,
    /* eslint-disable vue/prop-name-casing */
    product_specification: [Array, Object],
    product_price: String,
    vendor_code: String,
    /* eslint-enable vue/prop-name-casing */
})

const color = computed(() => {
    if (!Array.isArray(product_specification) || product_specification.length === 0) {
        return null
    }
    return product_specification[0]?.value || null
})
</script>

<template>
    <div class="mx-auto">
        <label
            class="block w-fit border border-transparent"
            :class="{ '!border-green': value === id }"
        >
            <input
                v-model="value"
                :value="id"
                type="radio"
                class="hidden peer"
            >
            <div
                class="bg-white w-[180px] m-1 transition-shadow  duration-300 hover:shadow-silver hover:cursor-pointer"
            >
                <div class="flex justify-center h-[180px]">
                    <slot name="image">
                        <picture class="w-full">
                            <img
                                :src="src.medium"
                                :alt="title"
                            />
                        </picture>
                    </slot>
                </div>
                <div class="text-black-dark text-desc-13 leading-4 mx-2 mt-5 pb-2 space-y-3">
                    <h3 class="line-clamp-2">{{ title }}</h3>
                    <p
                        v-if="color"
                        class="text-gray-dark truncate"
                    >{{ color }}</p>
                    <p>{{ product_price }} ₴</p>
                </div>
            </div>
        </label>
    </div>
</template>
