<script setup>
const value = defineModel({type: String});
const props = defineProps({
    type: {
        type: String,
        default: 'text'
    },
    name: String,
    label: String,
    required: Boolean,
    error: String,
    hasBorders: Boolean,
    placeholder: String
});
</script>

<template>
    <div class="relative z-0 w-full mb-5 group">
        <input
            v-model="value"
            :aria-required="props.required"
            :name="props.name"
            :type="props.type"
            :placeholder="props.placeholder ? `${props.placeholder}${props.required ? '*' : ''}` : ' '"
            class="block px-0 py-3 w-full text-green bg-transparent appearance-none focus:ring-0 focus:ring-transparent focus:border-green peer"
            :class="props.hasBorders ? 'border-gray-darkest text-desc-13 leading-snug font-normal py-5 px-3' : 'text-xl border-t-0 border-r-0 border-l-0 border-green'"
        />
        <label
            v-if="props.label"
            class="
                font-light absolute z-10 text-xl text-green pointer-events-none
                duration-300 transform -translate-y-8 scale-75 top-3 origin-[0]
                peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto
                peer-focus:text-green peer-placeholder-shown:scale-100
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8
            "
        >
            {{ props.label }}{{ props.required ? '*' : '' }}
        </label>
        <p
            v-if="props.error"
            class="absolute text-sm text-red"
        >{{ props.error }}</p>
    </div>
</template>

<style scoped>
.custom-autofill-bg input:-webkit-autofill,
.custom-autofill-bg input:-webkit-autofill:hover, 
.custom-autofill-bg input:-webkit-autofill:focus, 
.custom-autofill-bg input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(245 245 245) inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>