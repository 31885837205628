<script setup>
import {computed, onMounted, onBeforeUnmount, ref} from "vue";
import Button from "@/Components/Button.vue";
import {Link} from "@inertiajs/vue3";
import NewModal from "@/Components/NewModal.vue";
import ContactForm from "@/Components/ContactForm.vue";
import emitter from '@/plugins/eventBus.js'
import {scrollToContainer} from "@/plugins/helpers.js";

const {componentId, content, settings} = defineProps({
    componentId: String,
    content: Object,
    settings: Object,
});
const cta = computed(() => content?.cta);
const hasCta = computed(() => content?.cta != null);

const isOpenContactModal = ref(false)

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)))
onBeforeUnmount(() => {
    isOpenContactModal.value = false;
    emitter.$off(componentId)
})
</script>

<template>

    <div
        class="grid grid-cols-1 gap-4 md:gap-0"
        :class="{
            'md:grid-cols-2': settings.col_proportion === 'cols-1/2',
            'md:grid-cols-9': settings.col_proportion === 'cols-2/5-3/5',
        }"
    >
        <Component
            :is="settings.tag ?? 'h3'"
            class="content-heading text-center md:text-right col-span-1 self-center md:py-6 md:px-16"
            :class="{
                'md:col-span-3': settings.col_proportion === 'cols-2/5-3/5',
            }"
            v-html="content.title"
        ></Component>
        <div
            class="text-sm md:text-xl text-black col-span-1 font-normal content-text py-6 md:px-16 md:border-l-2 md:border-l-[#5A7B7E]"

            :class="{
                'md:col-span-6': settings.col_proportion === 'cols-2/5-3/5',
            }"
            v-html="content.text"
        ></div>
    </div>
    <template v-if="hasCta">
        <div class="flex justify-center mt-10 sm:mt-16 lg:mt-24">
            <Button
                v-if="cta.type === 'action'"
                kind="big"
                @click.prevent="isOpenContactModal = true"
            >
                {{ cta.title }}
            </Button>
            <Link
                v-else-if="cta.type === 'route'"
                :href="cta.action"
                :target="cta.target || '_self'"
                class="
                    block w-fit  font-semibold bg-green
                    text-white text-xl leading-none hover:bg-black rounded-44 transition-colors
                    duration-300 py-4 px-10 mx-auto lg:mx-0 lg:mr-auto
                "
            >
                {{ cta.title }}
            </Link>
            <a
                v-else-if="cta.type === 'link'"
                :href="cta.action"
                :target="cta.target || '_self'"
                class="
                    block w-fit  font-semibold bg-green
                    text-white text-xl leading-none hover:bg-black rounded-44 transition-colors
                    duration-300 py-4 px-10 mx-auto lg:mx-0 lg:mr-auto
                "
            >
                {{ cta.title }}
            </a>
        </div>
    </template>
    <Teleport to="body">
        <NewModal
            classes="p-5 sm:p-10 sm:pt-6 2xl:p-10 2xl:pt-4 pt-4 w-[90vw] sm:w-[540px] lg:w-[720px]"
            has-cross
            :is-open="isOpenContactModal"
            @close="isOpenContactModal = false"
        >
            <ContactForm
                class="mx-auto"
                endpoint="/contact"
                :request-type="$page.props.pageType"
                @submitted="isOpenContactModal = false"
            />
        </NewModal>
    </Teleport>
</template>

<style scoped >
.content-text > * + * {
    margin-top: 0.675rem;
}
@media screen and (min-width: 768px){
    .content-text > * + * {
        margin-top: 1rem;
    }

}
</style>