<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import {Link, useForm, usePage} from '@inertiajs/vue3';
import {useI18n} from "vue-i18n";

import AppLayout from "@/Layouts/AppLayout.vue";

import Button from "@/Components/Button.vue";
import Breadcrumb from '@/Components/Breadcrumb.vue';
import CartItem from '@/Components/CartItem.vue';

const {state} = useStore();
const {t} = useI18n();
const locale = computed(() => useI18n().locale);

const breadcrumbs = computed(() => [
    {
        title: t('titleHome'),
        disabled: false,
        href: state.links?.home?.[locale.value.value],
    },
    {
        title: t('titleCart'),
        disabled: true,
        href: '/',
    },
]);

const updateCount = (count, item) => useForm({count}).patch('/cart/' + item.id, {
    preserveScroll: true
});

const deleteItem = item => useForm({}).delete('/cart/' + item.id, {
    preserveScroll: true
});

const formattedTotalPrice = computed(() => new Intl.NumberFormat('uk-UA', {
    currencyDisplay: "narrowSymbol", style: 'currency', currency: 'UAH'
}).format(usePage().props.totalPrice))
</script>

<template>
    <AppLayout>
        <section class="py-10 ">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <section class="">
            <div
                v-if="$page.props.cartItems.length"
                class="container md:flex mb-16"
            >
                <div class="w-full md:w-7/12">
                    <CartItem
                        v-for="item in $page.props.cartItems"
                        :key="item.id"
                        :item="item"
                        class="border-b border-black last:border-none pt-12 first:pt-0"
                        @update-count="(count) => updateCount(count, item)"
                        @delete="deleteItem(item)"
                    />
                </div>
                <div class="w-full md:w-5/12 pb-12 md:pb-0 md:pl-8 lg:pl-24 xl:pl-52 text-black space-y-5 text-desc-13">
                    <p class="flex justify-between px-4 font-extrabold pb-4">
                        {{ t('earlyTotal') }}<span class="font-bold">{{ formattedTotalPrice }}</span>
                    </p>
                    <div>
                        <Link :href="state.links?.home?.[locale.value] || '/'">
                            <Button
                                kind="sharp-small"
                                secondary
                                class="uppercase w-full"
                            >
                                {{ t('continueShopping') }}
                            </Button>
                        </Link>
                    </div>
                    <div>
                        <Link :href="locale.value === 'uk' ? '/checkout' : `/${locale.value}/checkout`">
                            <Button
                                kind="sharp-small"
                                primary
                                class="uppercase w-full"
                            >
                                {{ t('goToCheckout') }}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="h-[39vh] text-2xl text-black"
            >
                <h1 class="mt-20 mb-10 text-center">{{ t('yourCartIsEmpty') + ' :(' }}</h1>
                <Link
                    class="block font-normal w-full text-center"
                    :href="state.links?.home?.[locale.value] || '/'"
                >
                    {{ t('goToShopping') }}
                </Link>
            </div>
        </section>
    </AppLayout>
</template>
