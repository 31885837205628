import {createI18n} from "vue-i18n";
import uk from './locales/uk.js'
import ru from './locales/ru.js'

export default createI18n({
    legacy: false,
    fallbackLocale: 'uk',
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    messages: {uk, ru }
})