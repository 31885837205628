<script setup>
import emitter from '@/plugins/eventBus.js'
import {onBeforeUnmount, onMounted, computed} from "vue";
import {scrollToContainer} from "@/plugins/helpers.js";

import ResponsivePicture from "@/Components/ResponsivePicture.vue";

const {componentId, content, settings} = defineProps({
    componentId: String,
    content: [Object],
    settings: Object
});

const hasTitle = computed(() => content?.section_title?.text)
const titlePosition = computed(() => content?.section_title?.position)
const hasImage = computed(() => content?.section_title?.image != null)
const titleImage = computed(() => content?.section_title?.image)

const alwaysOpen = computed(() => settings?.accordion_settings === 'without_collapse');

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)))
onBeforeUnmount(() => emitter.$off(componentId));
</script>

<template>
    <section
        :id="componentId"
        class="grid grid-cols-2 gap-4 lg:gap-16"
    >
        <div
            v-if="hasTitle"
            class="col-span-2"
            :class="{
                'md:col-span-1': titlePosition !== 'top',
                'lg:order-12': titlePosition === 'right',
            }"
        >
            <h2
                v-if="hasTitle"
                class="text-green text-2xl text-center md:text-4xl md:text-left md:mb-12 font-philosopher"
                v-html="content?.section_title.text"
            />

            <ResponsivePicture
                v-if="hasImage"
                :data="titleImage"
                img-classes="w-full lg:w-auto"
            />
        </div>
        <div
            class="col-span-2 md:col-span-1"
            :class="{'md:col-span-2': !hasTitle || titlePosition === 'top'}"
        >
            <div
                id="accordion-open"
                data-accordion="open"
                data-active-classes="dark:bg-gray-900 text-gray-900 dark:text-white"
                data-inactive-classes="text-gray-500 dark:text-gray-400"
                class="text-sm md:text-xl font-black space-y-6"
            >
                <div v-for="({ title, description }, index) in content.items">
                    <div :id="`accordion-open-heading-${index}`">
                        <button
                            type="button"
                            :disabled="alwaysOpen"
                            class="flex items-center w-full pt-2 md:pt-5 pb-5 md:pb-8  font-medium rtl:text-right text-black-500 border-b border-green gap-4"
                            :class="{ 'pt-4': index === 0 }"
                            :data-accordion-target="`#accordion-open-body-${index}`"
                            :aria-expanded="alwaysOpen"
                            :aria-controls="`accordion-open-body-${index}`"
                        >
                            <svg
                                v-if="!alwaysOpen"
                                data-accordion-icon
                                aria-hidden="true"
                                class="ml-2 rotate-180 shrink-0 transition-transform duration-500"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="6"
                                viewBox="0 0 9 6"
                                fill="none"
                            >
                                <path
                                    d="M1 4.75C1.4 4.35 3.83333 2.25 5 1.25L8.5 4.75"
                                    stroke="black"
                                />
                            </svg>
                            <span class="text-black text-left">{{ title }}</span>
                        </button>
                    </div>
                    <div
                        :id="`accordion-open-body-${index}`"
                        :class="{hidden: !alwaysOpen}"
                        :aria-labelledby="`accordion-open-heading-${index}`"
                    >
                        <div
                            class="pt-8 pr-3 pl-8 mb-2 text-black-dark  font-normal"
                            v-html="description"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

</style>